const Adress = () => {
  return (
    <>
      <h2>My adress:</h2>
      <p className="adress">Some street, 123</p>
      <p className="adress">Some city, 12344</p>
      <h3 className="adress">Phone: 123456677</h3>
      <h4 className="adress">Email: some@gmail.com</h4>
    </>
  );
};

export default Adress;
